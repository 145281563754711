var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card bs-5 mb-5"},[_c('div',{staticClass:"fl-te-c pb-3"},[_c('h4',{domProps:{"innerHTML":_vm._s('SERVICE')}}),_c('div',[_c('btn',{attrs:{"color":"success","icon":"fa fa-plus","size":"md","text":"Add"},on:{"click":function($event){return _vm.$refs.serviceAddModal.show()}}})],1)]),_c('vue-table',{ref:"table",attrs:{"fields":_vm.fields,"url":_vm.listUrl,"per-page":10},scopedSlots:_vm._u([{key:"disable",fn:function(ref){
var rowData = ref.rowData;
return [_c('checkbox-input',{on:{"input":function($event){return _vm.disable(rowData)}},model:{value:(rowData.disable),callback:function ($$v) {_vm.$set(rowData, "disable", $$v)},expression:"rowData.disable"}})]}},{key:"active",fn:function(ref){
var rowData = ref.rowData;
return [_c('checkbox-input',{on:{"input":function($event){return _vm.changeStatus(rowData)}},model:{value:(rowData.active),callback:function ($$v) {_vm.$set(rowData, "active", $$v)},expression:"rowData.active"}})]}},{key:"image",fn:function(ref){
var rowData = ref.rowData;
return [_c('img',{attrs:{"src":_vm.prefix + rowData.image,"width":"150px","alt":"img"}})]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"btn-group-tight"},[_c('delete-btn',{on:{"click":function($event){return _vm.setDelete(rowData)}}}),_c('edit-btn',{on:{"click":function($event){return _vm.setEdit(rowData)}}})],1)]}}])}),_c('modal',{ref:"serviceAddModal",attrs:{"title":"Add Service","width":"60r","no-close-on-backdrop":true,"header-color":"primary"}},[_c('AddService',{on:{"success":_vm.formSuccess}})],1),_c('modal',{ref:"serviceEditModal",attrs:{"title":"Edit Service","no-close-on-backdrop":true,"width":"60r","header-color":"primary"}},[_c('EditService',{attrs:{"initial-data":_vm.editingItem},on:{"success":_vm.formSuccess}})],1),_c('delete-modal',{ref:"serviceDeleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading-animation'),_vm._v(" Please wait while we delete "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.id)}}),_vm._v(". ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete the Service "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.title)}}),_vm._v(". Are you sure ? ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }