<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'SERVICE'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.serviceAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #disable="{rowData}">
                <checkbox-input v-model="rowData.disable" @input="disable(rowData)"></checkbox-input>
            </template>
            <template #active="{rowData}">
                <checkbox-input v-model="rowData.active" @input="changeStatus(rowData)"></checkbox-input>
            </template>
            <template #image="{rowData}">
                <img :src="prefix + rowData.image" width="150px" alt="img">
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Service" ref="serviceAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddService @success="formSuccess"></AddService>
        </modal>

        <modal title="Edit Service" ref="serviceEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditService :initial-data="editingItem" @success="formSuccess"></EditService>
        </modal>

        <delete-modal ref="serviceDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Service <b v-html="deletingItem && deletingItem.title"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../../data/urls';
import AddService from './AddService';
import EditService from './EditService';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';

export default {
    name       : 'Service',
    components : { AddService, EditService },
    computed   : { ...mapGetters(['currentUser']) },
    data () {
        return {
            prefix       : '',
            listUrl      : urls.admin.event.service.list,
            deleteUrl    : urls.admin.event.service.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'title',
                    sortField  : 'title',
                    title      : 'Title',
                    titleClass : ''
                },
                {
                    name       : 'title_description',
                    sortField  : 'title_description',
                    title      : 'Title Description',
                    titleClass : ''
                },
                {
                    name       : '__slot:disable',
                    sortField  : 'disable',
                    title      : 'Disabled in Events Page',
                    titleClass : ''
                },
                {
                    name       : '__slot:active',
                    sortField  : 'active',
                    title      : 'Display in website',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        changeStatus (item) {
            const that = this;
            item.api_key = this.currentUser.api_key;
            axios.form(urls.admin.event.service.activateDeactivateWebsite, item).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify('Successfully Updated Status..!', 'Success',
                        {
                            type : 'success'
                        });
                } else {
                    that.$notify(json.errors, 'Error',
                        {
                            type : 'warning'
                        });
                    item.active = !item.active;
                }
            }).catch(function (err) {
                item.active = !item.active;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        },
        formSuccess () {
            const refs = this.$refs;
            refs.serviceAddModal.close();
            refs.serviceEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            delete this.editingItem.description;
            this.editingItem.initial_description = item.description;
            this.$refs.serviceEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.serviceDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Service..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.serviceDeleteModal.close();
            this.$refs.table.refreshTable();
        },

        disable (item) {
            const that = this;
            axios.form(urls.admin.event.service.activateDeactivate, item).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify(json.message, 'Success',
                        {
                            type : 'success'
                        });
                } else {
                    that.$notify(json.message, 'Error',
                        {
                            type : 'warning'
                        });
                    item.disable = !item.disable;
                }
            }).catch(function (err) {
                item.disable = !item.disable;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        }
    }
};
</script>

<style scoped>

</style>
